import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TableLoading = () => {
    return (
        <Skeleton count={25} />
    );
}

export default TableLoading;