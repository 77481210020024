import './css/App.css';

import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlFood, faBroom, faCar, faFlask, faHelmetSafety, faShirt, faWrench, faFaceFrown } from '@fortawesome/free-solid-svg-icons';
import FoodElement from './elements/FoodElement';
import CleaningElement from './elements/CleaningElement';
import LaundryElement from './elements/LaundryElement';
import SafetyElement from './elements/SafetyElement';
import MaintenanceElement from './elements/MaintenanceElement';
import LabElement from './elements/LabElement';
import CarElement from './elements/CarElement';
import ComplaintElement from './elements/ComplaintElement';
import { GlobalProvider } from './GlobalContext';


function App() {
	const [currentModule, setCurrentModule] = useState("food");

	const elements = [
	  { name: "food", icon: faBowlFood },
	  { name: "cleaning", icon: faBroom },
	  { name: "laundry", icon: faShirt },
	  { name: "safety", icon: faHelmetSafety },
	  { name: "maintenance", icon: faWrench },
	  { name: "lab", icon: faFlask },
	  { name: "car", icon: faCar },
	  { name: "complaint", icon: faFaceFrown },
	];

	return (
		<GlobalProvider>
		<div className="App">
			<div className="flex flex-col justify-center items-center min-h-screen bg-slate-100 py-6 px-2">
				<ToastContainer />
				<div className="grid grid-cols-4 gap-3 mb-3 w-full justify-center align-center max-w-sm">
				{elements.map((module, index) => (
					<button
						key={module.name}
						onClick={() => setCurrentModule(module.name)}
						className={`bg-white py-2 rounded-md flex items-center justify-center ${
						currentModule === module.name ? 'border-b-2 border-blue-500' : ''
						} ${index !== elements.length - 1 ? '' : ''}`} 
					>
					<FontAwesomeIcon icon={module.icon} className={module.name === "complaint" ? ' text-red-600' : ' text-gray-700'} />
					</button>
				))}
				</div>
				{currentModule === "food" && <FoodElement/>}
				{currentModule === "cleaning" && <CleaningElement />}
				{currentModule === "laundry" && <LaundryElement />}
				{currentModule === "safety" && <SafetyElement />}
				{currentModule === "maintenance" && <MaintenanceElement />}
				{currentModule === "lab" && <LabElement />}
				{currentModule === "car" && <CarElement />}
				{currentModule === "complaint" && <ComplaintElement />}
			</div>
		</div>
		</GlobalProvider>
	);
}

export default App;
