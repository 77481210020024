import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [token, setToken] = useState('');

    return (
        <GlobalContext.Provider value={{ isSubmitting, setIsSubmitting, token, setToken }}>
            {children}
        </GlobalContext.Provider>
    );
};