import React, { useState, useContext } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import HomeButton from '../components/BackButton.js';
import SmallCard from '../components/SmallCard.js';
import { GlobalContext } from '../GlobalContext'; 
import SubmitButton from '../components/SubmitButton.js';
import { postData } from '../utils.js';
import { toast } from 'react-toastify';
import CustomInput from '../components/CustomInput.js';
import CustomTextArea from '../components/CustomTextArea.js';
import CustomSelect from '../components/CustomSelect.js';


const ComplaintElement = () => {
	const [stepNumber, setStepNumber] = useState(1);
	const {setIsSubmitting } = useContext(GlobalContext);
	const SERVICE_CHOICES = [
		{ id: 0, name: 'Тодорхойгүй' },
		{ id: 1, name: 'Ажлын цагаар хоолоор хангах' },
		{ id: 2, name: 'Оффис өрөөний цэвэрлэгээ' },
		{ id: 3, name: 'Хувцас угаалга' },
		{ id: 4, name: 'ХЭМАБ' },
		{ id: 5, name: 'Оффис, салбар нэгжийн засвар' },
		{ id: 6, name: 'Лабораторын шинжилгээ' },
		{ id: 7, name: 'Тээврийн хэрэгслийн засвар' }
	];

	const handleSubmit =  async (event) => {
        event.preventDefault();
		setIsSubmitting(true);
		const contact = event.target.contact.value;
		const description = event.target.description.value;
		const service = event.target.service.value;
		try {
			const result = await postData("https://ex.jurur.dev/complaint/create/", {
				service,
				contact,
				description,
			})
			if(result.error === "data"){
				toast.error('Системийн алдаа. Та дараа дахин оролдоно уу.');
			}
			else{
				toast.success('Таны хүсэлтийг амжилттай бүртгэлээ.');
				setStepNumber(2)
			}
		} catch (error) {
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    };



    return (
       <>
			{stepNumber === 1 && (
				<SmallCard>
					<HomeButton setStepNumber={setStepNumber}/>
					<div className='text-center text-lg font-bold mt-2'>Санал хүсэлт</div>
					<div className="mt-5 sm:mx-Car sm:w-full sm:max-w-sm">
						<form className="space-y-6" onSubmit={handleSubmit}>
							<CustomSelect 
								selectName="service"
								selectLabel="Үйлчилгээний төрөл"
								options={SERVICE_CHOICES}
							/>
							<CustomTextArea inputName="description" inputLabel="Үйл ажиллагаатай холбоотой санал хүсэлт"/>
							<CustomInput inputName="contact" inputLabel="Холбоо барих мэдээлэл (Хоосон орхиж болно)" inputType='text' required={false}/>
							<SubmitButton requestText={"Хүсэлт илгээх"}></SubmitButton>
						</form>
					</div>
				</SmallCard>
			)}

			{stepNumber === 2 && (
				<SmallCard>
					<HomeButton setStepNumber={setStepNumber}/>
					<div className='text-center text-lg font-bold mt-2'>Баярлалаа</div>
					<div className="mt-5 sm:mx-Car sm:w-full sm:max-w-sm text-center">
						Таны санал хүсэлтийг амжилттай хүлээн авлаа.
					</div>
				</SmallCard>
			)}
       </>
    );
}

export default ComplaintElement;