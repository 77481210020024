import React from 'react';

const CustomSelect = ({ selectName, selectLabel, options }) => {
    return (
        <div>
            <label htmlFor={selectName} className="block text-sm font-medium leading-6 text-gray-900">{selectLabel}</label>
            <select
                id={selectName}
                name={selectName}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
                {options.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default CustomSelect;