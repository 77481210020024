import React from 'react';

const SmallCard = ({ children }) => {
  return (
    <div className="mx-5 w-full rounded-xl  max-w-sm  overflow-hidden bg-white">
        <div id="ticket" className="flex min-h-full flex-1 flex-col justify-center p-6">
            { children }
        </div>
    </div>
  );
}

export default SmallCard;