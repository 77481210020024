import React, { useState, useContext, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import TokenRetriever from '../components/TokenRetriever.js';
import HomeButton from '../components/BackButton.js';
import SmallCard from '../components/SmallCard.js';
import CustomSelect from '../components/CustomSelect.js';
import SubmitButton from '../components/SubmitButton.js';
import { fetchData, postData } from '../utils.js';
import { toast } from 'react-toastify';
import { GlobalContext } from '../GlobalContext'; 
import TableLoading from '../components/TableLoading.js';

const LabElement = () => {
	const [tests, setTests] = useState([]);
	const [stepNumber, setStepNumber] = useState(1);
	const {isSubmitting, setIsSubmitting, token } = useContext(GlobalContext);
	const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
	const [listItems, setListItems] = useState([]);


	const fetchDataAsync = async () => {
		try {
			const url = "https://ex.jurur.dev/lab-test/list/";
			const result = await fetchData(url);
			setTests(result)
			} catch (error) {
				toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
	};


	const handleSubmit =  async (event) => {
        event.preventDefault();
		setIsSubmitting(true);
		const test = event.target.test.value;
		try {
			const result = await postData("https://ex.jurur.dev/lab-request/create/", {
				"token": token,
				test
			})
			if(result.error === "user"){
				toast.error('Бүртгэл олдсонгүй.');
				setStepNumber(1)
			}
			else if(result.error === "data"){
				toast.error('Системийн алдаа. Та дараа дахин оролдоно уу.');
			}
			else{
				toast.success('Таны хүсэлтийг амжилттай бүртгэлээ.');
				setStepNumber(1)
			}
		} catch (error) {
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    };


	const getList =  useCallback(async (page) => {
		setIsSubmitting(true);
		try {
			const result = await postData(`https://ex.jurur.dev/lab-request/list/?page=${page}`, {
				"token": token
			})
			if(result.error === "user"){
				toast.error('Бүртгэл олдсонгүй.');
				setStepNumber(1)
			}
			else{
				setListItems(result.results)
				setTotalPages(Math.ceil(result.count / 20));
			}
		} catch (error) {
			console.log(error)
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    }, [token, setIsSubmitting]);


	const handlePageChange = (page) => {
        setCurrentPage(page);
    };


	useEffect(() => {
        if (stepNumber === 3) {
            getList(currentPage);
        }
    }, [currentPage, stepNumber, getList]);


	useEffect(() => {
		fetchDataAsync();
    }, []);


    return (
       <>
	   {stepNumber === 1 && (
		<TokenRetriever 
			setStepNumber={setStepNumber} 
			title="Лабораторын шинжилгээ"
			historyText="Шинжилгээний түүх"
			requestText="Шинжилгээний хүсэлт"
		/>
		)}

		{stepNumber === 2 && (
			<SmallCard>
				<HomeButton setStepNumber={setStepNumber}/>
				<div className='text-center text-lg font-bold mt-2'>Шинжилгээний хүсэлт</div>
				<div className='mt-5'>
					<form className="space-y-6" onSubmit={handleSubmit}>
						<CustomSelect selectName="test" selectLabel="Шинжилгээ" options={tests} />
						<SubmitButton requestText={"Хүсэлт илгээх"}></SubmitButton>
					</form>
				</div>
			</SmallCard>
		)}

		{stepNumber === 3 && (
			<SmallCard>
				<HomeButton setStepNumber={setStepNumber}/>
				<div className='text-center text-lg font-bold mt-2'>Шинжилгээний түүх</div>
				<div className='mt-5 text-center'>
					
					{isSubmitting ? (
						<TableLoading/>
					):(
						<div className='flex'>
						<table className='table mt-3 border-collapse border border-slate-400 text-xs w-full'>
							<thead>
								<tr>
									<th className='border border-slate-300 px-2 py-1 break-words border-l-slate-900 border-t-slate-900'>Огноо</th>
									<th className='border border-slate-300 px-2 py-1 break-words border-t-slate-900'>Шинжилгээ</th>
									<th className='border border-slate-300 px-2 py-1 break-words border-r-slate-900 border-t-slate-900'>Төлөв</th>
								</tr>
								<tr>
									<th colSpan={3} className='border border-slate-300 border-b-slate-900 px-2 py-1 break-words border-r-slate-900 border-l-slate-900'>Үр дүн</th>
								</tr>
							</thead>
							<tbody>
								{listItems.map((request, index) => (
									<React.Fragment key={index} >
										<tr>
											<td className='border border-slate-300 px-2 py-1 break-words border-l-slate-900'>
												{new Date(request.created).toLocaleDateString([], { month: 'numeric', day: 'numeric' })}
											</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.test_display}</td>
											<td className='border border-slate-300 px-2 py-1 break-words border-r-slate-900'>{request.status_display}</td>
										</tr>
										<tr>
											<td colSpan={3} className='border border-slate-300 px-2 py-1 break-words border-b-slate-900 border-r-slate-900 border-l-slate-900'>
												{request.result || "Үр дүн байхгүй"}
											</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
					)}
					
					<div className="pagination flex  justify-between mt-5 text-xs">
						<button
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
						>
							Өмнөх
						</button>


						<span>Хуудас {currentPage}/ {totalPages}</span>
						
						
						<button
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={currentPage === totalPages}
						>
							Дараах
						</button>
					</div>
				</div>
			</SmallCard>
		)}
       </>
    );
}

export default LabElement;