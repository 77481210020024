import React from 'react';

const CounterButton = ({ children, count, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex flex-col items-center justify-center p-4 bg-white text-blue-800 rounded-lg hover:bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-300"
    >
      <div className="flex flex-col items-center justify-center mb-2 space-y-2">
        {children}
      </div>
      <div className="text-lg font-semibold">
        {count}
      </div>
    </button>
  );
};

export default CounterButton;