import React from 'react';

const FileUploader = ({ onFileSelect, keyProp, fileName, clearFileName  }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file);
        }
    };

    return (
        <div>
            <input 
                id='file_input'
                type="file"
                style={{ display: 'none' }}
                key={keyProp}
                onChange={handleFileChange}
            />
            {fileName && <span className="ml-2 text-sm">{fileName}</span>}
            <button 
                className="ms-4 px-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                onClick={() => document.getElementById('file_input').click()}
            >
                Файл сонгох
            </button>
        </div>
    );
}

export default FileUploader;