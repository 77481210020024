import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';


const CustomInput = ({ inputName, inputType="text", inputLabel }) => {
    return (
        <div>
            <label htmlFor={inputName} className="block text-sm font-medium leading-6 text-gray-900">{inputLabel}</label>
            <input
                id={inputName}
                name={inputName}
                type={inputType}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
        </div>
    );
}

export default CustomInput;