import React, { useState, useContext, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import TokenRetriever from '../components/TokenRetriever.js';
import HomeButton from '../components/BackButton.js';
import SmallCard from '../components/SmallCard.js';
import { GlobalContext } from '../GlobalContext'; 
import SubmitButton from '../components/SubmitButton.js';
import { postData } from '../utils.js';
import { toast } from 'react-toastify';
import CustomInput from '../components/CustomInput.js';
import CustomTextArea from '../components/CustomTextArea.js';
import TableLoading from '../components/TableLoading.js';


const SafetyElement = () => {
	const [stepNumber, setStepNumber] = useState(1);
	const {isSubmitting, setIsSubmitting, token } = useContext(GlobalContext);
	const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
	const [listItems, setListItems] = useState([]);

	const handleSubmit =  async (event) => {
        event.preventDefault();
		setIsSubmitting(true);
		const location = event.target.location.value;
		const room = event.target.room.value;
		const description = event.target.description.value;
		try {
			const result = await postData("https://ex.jurur.dev/safety-request/create/", {
				"token": token,
				location,
				room,
				description,
			})
			if(result.error === "user"){
				toast.error('Бүртгэл олдсонгүй.');
				setStepNumber(1)
			}
			else if(result.error === "data"){
				toast.error('Системийн алдаа. Та дараа дахин оролдоно уу.');
			}
			else{
				toast.success('Таны хүсэлтийг амжилттай бүртгэлээ.');
				setStepNumber(1)
			}
		} catch (error) {
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    };



	const getList =  useCallback(async (page) => {
		setIsSubmitting(true);

		try {
			const result = await postData(`https://ex.jurur.dev/safety-request/list/?page=${page}`, {
				"token": token
			})
			if(result.error === "user"){
				toast.error('Бүртгэл олдсонгүй.');
				setStepNumber(1)
			}
			else{
				setListItems(result.results)
				setTotalPages(Math.ceil(result.count / 20));
			}
		} catch (error) {
			console.log(error)
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    }, [token, setIsSubmitting]);


	const handlePageChange = (page) => {
        setCurrentPage(page);
    };


	useEffect(() => {
        if (stepNumber === 3) {
            getList(currentPage);
        }
    }, [currentPage, stepNumber, getList]);


    return (
       <>
	   	{stepNumber === 1 && (
		<TokenRetriever 
			setStepNumber={setStepNumber} 
			title="ХЭМАБ"
			historyText="Аюулын түүх"
			requestText="Аюул мэдээлэх"
		/>
		)}

		{stepNumber === 2 && (
			<SmallCard>
				<HomeButton setStepNumber={setStepNumber}/>
				<div className='text-center text-lg font-bold mt-2'>Аюул мэдээлэх</div>
				<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<CustomInput inputName="location" inputLabel="Оффис/Байршил" inputType='text'/>
						<CustomInput inputName="room" inputLabel="Өрөө" inputType='text'/>
						<CustomTextArea inputName="description" inputLabel="Нэмэлт тайлбар"/>
						<SubmitButton requestText={"Мэдээлэх"}></SubmitButton>
					</form>
				</div>
			</SmallCard>
		)}

		{stepNumber === 3 && (
			<SmallCard>
				<HomeButton setStepNumber={setStepNumber}/>
				<div className='text-center text-lg font-bold mt-2'>Аюулын түүх</div>
				<div className='mt-5 text-center'>
					
					{isSubmitting ? (
						<TableLoading/>
					):(
						<div className='flex'>
						<table className='table mt-3 border-collapse border border-slate-400 text-xs w-full'>
							<thead>
								<tr>
									<th className='border border-slate-300 px-2 py-1 break-words'>Огноо</th>
									<th className='border border-slate-300 px-2 py-1 break-words'>Тайлбар</th>
									<th className='border border-slate-300 px-2 py-1 break-words'>Төлөв</th>
								</tr>
							</thead>
							<tbody>
								{listItems.map((request, index) => (
									<tr key={index}>
										<td className='border border-slate-300 px-2 py-1 break-words'>{new Date(request.created).toLocaleDateString([], { month: 'numeric', day: 'numeric' })}</td>
										<td className='border border-slate-300 px-2 py-1 break-words'>{request.description}</td>
										<td className='border border-slate-300 px-2 py-1 break-words'>{request.status_display}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					)}
					
					<div className="pagination flex  justify-between mt-5 text-xs">
						<button
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
						>
							Өмнөх
						</button>

						<span>Хуудас {currentPage}/ {totalPages}</span>
						
						
						<button
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={currentPage === totalPages}
						>
							Дараах
						</button>
					</div>
				</div>
			</SmallCard>
		)}
       </>
    );
}

export default SafetyElement;