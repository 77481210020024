import React, { useState, useContext, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import TokenRetriever from '../components/TokenRetriever.js';
import HomeButton from '../components/BackButton.js';
import SmallCard from '../components/SmallCard.js';
import CounterButton from '../components/CounterButton.js';
import { PiPants, PiHardHat, PiBoot, PiHand, PiShirtFolded, PiTShirt, PiFaceMask, PiQuestion } from "react-icons/pi";
import CustomTextArea from '../components/CustomTextArea.js';

import { GlobalContext } from '../GlobalContext';
import { postData } from '../utils.js';
import { toast } from 'react-toastify';
import SubmitButton from '../components/SubmitButton.js';
import TableLoading from '../components/TableLoading.js';

const Laundry = () => {
	const [stepNumber, setStepNumber] = useState(1);
	const [hatCount, setHatCount] = useState(0);
	const [maskCount, setMaskCount] = useState(0);
	const [shirtCount, setShirtCount] = useState(0);
	const [apronCount, setApronCount] = useState(0);
	const [gloveCount, setGloveCount] = useState(0);
	const [pantCount, setPantCount] = useState(0);
	const [bootCount, setBootCount] = useState(0);
	const [otherCount, setOtherCount] = useState(0);

	const { isSubmitting, setIsSubmitting, token } = useContext(GlobalContext);
	const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
	const [listItems, setListItems] = useState([]);


	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsSubmitting(true);
		const description = event.target.description.value;

		try {
			const result = await postData("https://ex.jurur.dev/laundry-request/create/", {
				token: token,
				description,
				hat_count: hatCount,
				mask_count: maskCount,
				shirt_count: shirtCount,
				apron_count: apronCount,
				glove_count: gloveCount,
				pant_count: pantCount,
				boot_count: bootCount,
				other_count: otherCount
			});

			if (result.error === "user") {
				toast.error('Бүртгэл олдсонгүй.');
				setStepNumber(1);
			} else if (result.error === "data") {
				toast.error('Системийн алдаа. Та дараа дахин оролдоно уу.');
			} else {
				toast.success('Таны хүсэлтийг амжилттай бүртгэлээ.');
				setStepNumber(1);
			}
		} catch (error) {
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}

		setIsSubmitting(false);
	};


	const resetCounts = () => {
		setHatCount(0);
		setMaskCount(0);
		setShirtCount(0);
		setApronCount(0);
		setGloveCount(0);
		setPantCount(0);
		setBootCount(0);
		setOtherCount(0);
	};


	const getList =  useCallback(async (page) => {
		setIsSubmitting(true);

		try {
			const result = await postData(`https://ex.jurur.dev/laundry-request/list/?page=${page}`, {
				"token": token
			})
			if(result.error === "user"){
				toast.error('Бүртгэл олдсонгүй.');
				setStepNumber(1)
			}
			else{
				setListItems(result.results)
				setTotalPages(Math.ceil(result.count / 20));
			}
		} catch (error) {
			console.log(error)
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    }, [token, setIsSubmitting]);


	const handlePageChange = (page) => {
        setCurrentPage(page);
    };


	useEffect(() => {
		resetCounts();
        if (stepNumber === 3) {
            getList(currentPage);
        }
    }, [currentPage, stepNumber, getList]);

	return (
		<>
			{stepNumber === 1 && (
				<TokenRetriever
					setStepNumber={setStepNumber}
					title="Хувцас угаалга"
					historyText="Угаалгын түүх"
					requestText="Хувцас угаалгах"
				/>
			)}


			{stepNumber === 2 && (
				<SmallCard>
					<HomeButton setStepNumber={setStepNumber} />
					<div className='text-center text-lg font-bold mt-2'>Хувцас угаалгах</div>
					<div className='mt-5 text-center'>

						<button
							onClick={resetCounts}
							className="text-xs mb-3 w-full bg-white border border-red-500 text-red-500 font-bold py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-300 transition duration-300"
						>
							Бүгдийг нь 0 болгох
						</button>

						<form className="space-y-6" onSubmit={handleSubmit}>

							<div className="grid grid-cols-4 gap-3 mb-3 w-full justify-center align-center text-xs">
								<CounterButton
									count={hatCount}
									onClick={() => setHatCount(hatCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiHardHat size={30} />
										Малгай
									</div>
								</CounterButton>

								<CounterButton
									count={maskCount}
									onClick={() => setMaskCount(maskCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiFaceMask size={30} />
										Маск
									</div>
								</CounterButton>

								<CounterButton
									count={shirtCount}
									onClick={() => setShirtCount(shirtCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiTShirt size={30} />
										Цамц
									</div>
								</CounterButton>

								<CounterButton
									count={apronCount}
									onClick={() => setApronCount(apronCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiShirtFolded size={30} />
										Хормогч
									</div>
								</CounterButton>

								<CounterButton
									count={gloveCount}
									onClick={() => setGloveCount(gloveCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiHand size={30} />
										Бээлий
									</div>
								</CounterButton>


								<CounterButton
									count={pantCount}
									onClick={() => setPantCount(pantCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiPants size={30} />
										Өмд
									</div>
								</CounterButton>


								<CounterButton
									count={bootCount}
									onClick={() => setBootCount(bootCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiBoot size={30} />
										Гутал
									</div>
								</CounterButton>


								<CounterButton
									count={otherCount}
									onClick={() => setOtherCount(otherCount + 1)}
								>
									<div className="flex flex-col items-center space-y-1">
										<PiQuestion size={30} />
										Бусад
									</div>
								</CounterButton>

							</div>

							<CustomTextArea inputName="description" inputLabel="Нэмэлт тайлбар" required={false} />
							<SubmitButton requestText={"Хүсэлт илгээх"}></SubmitButton>
						</form>

					</div>
				</SmallCard>
			)}

			{stepNumber === 3 && (
				<SmallCard>
					<HomeButton setStepNumber={setStepNumber} />
					<div className='text-center text-lg font-bold mt-2'>Угаалгын түүх</div>
					<div className='mt-5 text-center'>
						
						{isSubmitting ? (
							<TableLoading/>
						):(
							<div className='flex'>
							<table className='table mt-3 border-collapse border border-slate-400 text-xs w-full'>
								<thead>
									<tr>
										<th className='border border-slate-300 px-2 py-1 break-words'>Огноо</th>
										<th className='border border-slate-300 break-words'><PiHardHat size={20} /></th>
										<th className='border border-slate-300 break-words'><PiFaceMask size={20} /></th>
										<th className='border border-slate-300 break-words'><PiTShirt size={20} /></th>
										<th className='border border-slate-300 break-words'><PiShirtFolded size={20} /></th>
										<th className='border border-slate-300 break-words'><PiHand size={20} /></th>
										<th className='border border-slate-300 break-words'><PiPants size={20} /></th>
										<th className='border border-slate-300 break-words'><PiBoot size={20} /></th>
										<th className='border border-slate-300 break-words'><PiQuestion size={20} /></th>
										<th className='border border-slate-300 px-2 py-1 break-words'>Төлөв</th>
									</tr>
								</thead>
								<tbody>
									{listItems.map((request, index) => (
										<tr key={index}>
											<td className='border border-slate-300 px-2 py-1 break-words'>{new Date(request.created).toLocaleDateString([], { month: 'numeric', day: 'numeric' })}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.hat_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.mask_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.shirt_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.apron_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.glove_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.pant_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.boot_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.other_count}</td>
											<td className='border border-slate-300 px-2 py-1 break-words'>{request.status_display}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						)}
						
						<div className="pagination flex  justify-between mt-5 text-xs">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
							>
								Өмнөх
							</button>


							<span>Хуудас {currentPage}/ {totalPages}</span>
							
							
							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages}
							>
								Дараах
							</button>
						</div>
					</div>
				</SmallCard>
			)}


		</>
	);
}

export default Laundry;