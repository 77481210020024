import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';


const HomeButton = ({ setStepNumber}) => {
    return (
        <button href="#" className="text-sm inline-flex font-medium items-center text-blue-600 hover:underline" onClick={e => setStepNumber(1)}>
            <FontAwesomeIcon icon={faArrowLeft} className='me-2'/>Нүүр
        </button>
    );
}

export default HomeButton;