// import logo from '../images/logo.svg';
import { GlobalContext } from '../GlobalContext'; 
import React, { useState, useEffect, useContext } from 'react';
import {fetchData, postData} from '../utils.js';
import 'react-toastify/dist/ReactToastify.min.css';
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBowlFood, faCheckCircle, faCloudMoon, faMoon, faQuestionCircle, faSun, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import FileUploader from '../components/FileUploader.js';
import { toast } from 'react-toastify';

const FoodElement = () => {
	// eslint-disable-next-line
	const {isSubmitting, setIsSubmitting, token, setToken } = useContext(GlobalContext);
    const [identificationId, setIdentificationId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
	const [isChecked, setIsChecked] = useState('');
	const [date, setDate] = useState('');
	const [submitType, setSubmitType] = useState(1);
	const [ticketData, setTicketData] = useState(null);
	const [stepNumber, setStepNumber] = useState(1);
	const [personalScheduleDates, setPersonalScheduleDates] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const [personalSchedules, setPersonalSchedules] = useState([]);
	const [scheduleDates, setScheduleDates] = useState([]);
	const [schedules, setSchedules] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [currentMeal, setCurrentmeal] = useState("afternoon");
	const [fileKey, setFileKey] = useState(Date.now());
	const [fileName, setFileName] = useState('');


	const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

	function formatDate(dateString) {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');  // getMonth() returns month from 0-11
		const day = date.getDate().toString().padStart(2, '0');
		return `${year}/${month}/${day}`;
	}

	const fetchDataAsync = async () => {
		try {
			const url = "https://ex.jurur.dev/current-date/";
			const result = await fetchData(url);
			setDate(result.current_date)
			} catch (error) {
				toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
	};


	useEffect(() => {
        const storedIdentificationId = localStorage.getItem('identificationId');
        const storedPhoneNumber = localStorage.getItem('phoneNumber');
        const storedIsChecked = localStorage.getItem('isChecked');
		if (storedIdentificationId) {
			setIdentificationId(storedIdentificationId);
		}
		if (storedPhoneNumber) {
			setPhoneNumber(storedPhoneNumber);
		}
		if (storedIsChecked) {
			setIsChecked(storedIsChecked === 'true');
		}
		fetchDataAsync();
    }, []);


	const handleSubmit =  async (event) => {
        event.preventDefault();
		setIsSubmitting(true);
		localStorage.setItem('isChecked', isChecked);
		if (isChecked) {
			localStorage.setItem('identificationId', identificationId);
			localStorage.setItem('phoneNumber', phoneNumber);
		} else {
			localStorage.removeItem('identificationId');
			localStorage.removeItem('phoneNumber');
		}

		try {
			if(submitType===1){
				const result = await postData("https://ex.jurur.dev/get-ticket/", {
					"identification_id": identificationId,
					"phone": phoneNumber
				})
				if(result.error === "User"){
					toast.error('Бүртгэл олдсонгүй. Та it@jurur.mn хаягт хандан бүртгэлээ шинэчилнэ үү.');
				}
				else if (result.error === "Out"){
					toast.error('Хоолны цагаар тасалбараа авна уу.');
				}
				else if (result.error === "Schedule"){
					toast.error('Танд өнөөдөр хоол авах хуваарь байхгүй байна.');
				}
				else if (result.error === "morning"){
					toast.error('Танд өглөөний хуваарь байхгүй байна. Хуваарийн удирдлагаар оруулна уу.');
				}
				else if (result.error === "afternoon"){
					toast.error('Танд өдрийн хуваарь байхгүй байна. Хуваарийн удирдлагаар оруулна уу.');
				}
				else if (result.error === "evening"){
					toast.error('Танд оройн хуваарь байхгүй байна. Хуваарийн удирдлагаар оруулна уу.');
				}
				else if (result.error === "night"){
					toast.error('Танд шөнийн хуваарь байхгүй байна. Хуваарийн удирдлагаар оруулна уу.');
				}
				else{
					setTicketData(result.ticket)
					setStepNumber(2);
				}
			}
			if(submitType===2){
				const result = await postData("https://ex.jurur.dev/get-info/", {
					"identification_id": identificationId,
					"phone": phoneNumber
				})
				if(result.error === "User"){
					toast.error('Бүртгэл олдсонгүй.');
				}else{
					setPersonalSchedules(result.schedules);
					setPersonalScheduleDates(result.dates);
					setIsAdmin(result.is_admin);
					setStepNumber(3);
				}
			}
		} catch (error) {
			toast.error('Сүлжээнд холбогдоход алдаа гарлаа.');
		}
		setIsSubmitting(false); 
    };


	const scheduleAdmin =  async () => {
		setIsSubmitting(true);
		const identificationId = localStorage.getItem('identificationId');
        const phoneNumber = localStorage.getItem('phoneNumber');
		const result = await postData("https://ex.jurur.dev/schedule-admin/", {
			"identification_id": identificationId,
			"phone": phoneNumber
		})
		if(result.error === "User"){
			toast.error('Бүртгэл олдсонгүй.');
		}else{
			setScheduleDates(result.dates);
			setSchedules(result.schedules);
			setStepNumber(4);
		}
		setIsSubmitting(false); 
	}


	const changeSchedule = async (name, pk, index, jindex, status) =>{
		const newStatus = !status;
		if (status === null) {
			return;
		}
		const updatedSchedules = schedules.map((item, idx) => 
			idx === index ? {
				...item,
				schedules: item.schedules.map((subItem, subIdx) => 
					subIdx === jindex ? { ...subItem, [currentMeal]: null } : subItem
				)
			} : item
		);
		setSchedules(updatedSchedules);
		try {
			const data = {
				"identification_id": identificationId,
				"phone": phoneNumber,
				"schedules": [{
					"pk": pk,
					"date": scheduleDates[jindex],
					"status": newStatus,
					"meal": currentMeal
				}]
			};
			console.log(data)
			const result = await postData("https://ex.jurur.dev/set-schedule/", data);
	
			if (result.success) {
				const newSchedules = schedules.map((item, idx) => 
					idx === index ? {
						...item,
						schedules: item.schedules.map((subItem, subIdx) => 
							subIdx === jindex ? { ...subItem, [currentMeal]: newStatus } : subItem
						)
					} : item
				);
				setSchedules(newSchedules);
			}
		} catch (error) {
			toast.error(`${name} ажилтны хуваарь оруулахад алдаа гарлаа.`);
		}
	}


	const exportToExcel = () => {
        const tableData = [];
    	var headers = ['Нэр', 'Нэгж', 'Таних тэмдэг'];
		headers = headers.concat(scheduleDates);
        tableData.push(headers);
        schedules.forEach((schedule) => {
            const rowData = [];
            rowData.push(schedule.name);
            rowData.push(schedule.department);
            rowData.push(schedule.pk);
			scheduleDates.forEach((value)=>{
				rowData.push('1');
			})
            tableData.push(rowData);
        });
        const ws = XLSX.utils.aoa_to_sheet(tableData);
		ws['!cols'] = [
			{wch: 40},
			{wch: 40},
			{wch: 12}
		];
		scheduleDates.forEach(() => {
			ws['!cols'].push({wch: 10});
		});
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `template${scheduleDates[0]}.xlsx`);
    };


	const handleFileSelect = (file) => {
		setSelectedFile(file);
		setFileName(file.name); 
    };


	const clearFileName = () => {
        setFileName('');
    };


	const processFile = () =>{
		if (!selectedFile) {
			toast.error("Файл сонгоно уу.");
			return;
		}
		const reader = new FileReader();
		reader.onload = async (event) => {
			setIsSubmitting(true); 
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, { type: 'array' });
			const worksheet = workbook.Sheets["Sheet1"];
			const json = XLSX.utils.sheet_to_json(worksheet,  { defval: "0" });
			console.log(json)
			var result = [];
			for(var x in json[0]) result.push(x);
			const dateArray = filterDates(result);
			console.log(dateArray)
			for (const item of json) {
				var row = []
				for(var i in item) row.push(item[i]);
				const slicedRow = row.slice(3);
				var schedules = []
				for (let i = 0; i < dateArray.length; i++) {
					schedules.push({
						"pk": row[2],
						"date": dateArray[i],
						"meal": currentMeal,
						"status": slicedRow[i] === 1 ||  slicedRow[i] === "1" ? true : false
					})
				}
				try {
					await postData("https://ex.jurur.dev/set-schedule/", {
						"identification_id": identificationId,
						"phone": phoneNumber,
						"schedules": schedules
					});
				} catch (error) {
					toast.error(`${row[2]} ажилтны хуваарь оруулахад алдаа гарлаа.`);
				}
			}
			scheduleAdmin();
			setSelectedFile(null);
			clearFileName();
			setFileKey(Date.now());
			setIsSubmitting(false); 
		};
		reader.readAsArrayBuffer(selectedFile);
	}


	const filterDates = (array) => {
		const datePattern = /^\d{4}-\d{2}-\d{2}$/;
		const dates = array.filter(item => datePattern.test(item));
		return dates;
	  };


	const changePersonalSchedule = async (index, date, meal, status, pk) =>{
		const newStatus = !status;
		if (status === null) {
			return;
		}
		const updatedSchedules = personalSchedules.map((item, idx) =>
			idx === index ? { ...item, [meal]: null } : item
		);
		setPersonalSchedules(updatedSchedules);
		try {
			const result = await postData("https://ex.jurur.dev/set-schedule/", {
				"identification_id": identificationId,
				"phone": phoneNumber,
				"schedules": [{
					"pk": pk,
					"meal": meal,
					"date": date,
					"status": newStatus
				}]
			});
			if (result.success) {
				const successUpdatedSchedules = personalSchedules.map((item, idx) =>
					idx === index ? { ...item, [meal]: newStatus } : item
				);
				setPersonalSchedules(successUpdatedSchedules);
			} 
		} catch (error) {
			toast.error(`Хуваарь оруулахад алдаа гарлаа.`);
		}
	}


	const ScheduleRow = ({ row, index, changeSchedule, currentMeal }) => {
		const renderIcon = (status) => {
			if (status === null) {
				return <FontAwesomeIcon icon={faQuestionCircle} color="grey" />;
			}
			return status ? <FontAwesomeIcon icon={faCheckCircle} color="green" /> : <FontAwesomeIcon icon={faTimesCircle} color="red" />;
		};
		return (
			<tr key={row.pk}>
				<td className='border border-slate-300 px-2 py-2 font-bold text-xs'>{row.name}</td>
				<td className='border border-slate-300 px-2 py-2 font-bold text-xs'>{row.department}</td>
				{row.schedules.map((single, jindex) => (
					<td key={`${row.pk}-${jindex}`} className='border border-slate-300 px-2 py-2 font-bold text-xs text-center' onClick={() => changeSchedule(row.name, row.pk, index, jindex, single[currentMeal])}>
						{currentMeal in single && renderIcon(single[currentMeal])}
					</td>
				))}
			</tr>
		);
	};
	

	const SchedulesTable = ({ schedules, changeSchedule, currentMeal }) => {
		return (
				<tbody>
					{schedules.map((row, index) => (
						<ScheduleRow key={row.pk} row={row} index={index} changeSchedule={changeSchedule} currentMeal={currentMeal} />
					))}
				</tbody>
		);
	};

   
    return (
       <>
       {stepNumber === 1 && (
			<div className="mx-5 w-full rounded-xl  max-w-sm  overflow-hidden bg-white">
				<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
                    <div className='text-center text-lg font-bold'>Хоолны тасалбар</div>
					<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
						<form className="space-y-6" onSubmit={handleSubmit}>
							<div>
								<div className="flex items-center justify-between">
									<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
										Ажилтны ID код
									</label>
								</div>
								<div className="mt-2">
									<input
										id="identification-id"
										name="identification-id"
										type="text"
										autoComplete="identification-id"
										required
										value={identificationId}
										onChange={e => setIdentificationId(e.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
									<div className='mt-1 text-sm text-gray-500 italic font-light'>Та өөрийн ажилтны код болон бүртгэлтэй утасны дугаараа хүний нөөцийн ажилтнаасаа лавлах боломжтой.</div>
								</div>
							</div>

							<div>
								<div className="flex items-center justify-between">
									<label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
										Бүртгэлтэй утасны дугаар
									</label>
								</div>
								<div className="mt-2">
									<input
										id="phone-number"
										name="phone-number"
										type="text"
										autoComplete="phone-number"
										required
										value={phoneNumber}
										onChange={e => setPhoneNumber(e.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
									
								</div>
							</div>


							<div className="flex items-center justify-between">
								<div className="flex items-start">
									<div className="flex items-center h-5">
										<input 
											id="remember" 
											aria-describedby="remember" 
											type="checkbox" 
											checked={isChecked}
											onChange={handleCheckboxChange} 
											className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
										/>
									</div>
									<div className="ml-3 text-sm">
										<label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Мэдээлэл сануулах</label>
									</div>
								</div>
							</div>

							<div>
								<input
									disabled
									id="date"
									name="date"
									type="text"
									autoComplete="date"
									value={date}
									className="block w-full rounded-md border-0 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
							
							<div>
							<button
								disabled={isSubmitting}
								id="submit-button"
								type="submit"
								onClick={e => setSubmitType(2)}
								className="text-sm mb-3 flex w-full justify-end font-medium items-center text-blue-600 hover:underline"
							>
									{isSubmitting ? 
									<div role="status">
										<svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
								<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
							</svg>
										<span className="sr-only">Loading...</span>
									</div>
									: 'Хуваарийн удирдлага'}
								</button>
								<button
									disabled={isSubmitting}
									id="submit-button"
									type="submit"
									onClick={e => setSubmitType(1)}
									className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									{isSubmitting ? 
									<div role="status">
										<svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
								<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
							</svg>
										<span className="sr-only">Loading...</span>
									</div>
									: 'Хоолны тасалбар авах'}
								</button>
							</div>
						</form>

						
					</div>
				</div>
			</div>
		)}

		{stepNumber === 2 && (
			<div className="mx-5 w-full rounded-xl  max-w-sm  overflow-hidden bg-white">
				<div id="ticket" className="flex flex-col min-h-full flex-1 max-w-sm justify-center">
				
					<div className="p-6">
						
						<div className="flex items-start mt-3">
							<div className="flex items-start\ text-lg">
								{ticketData.name}
							</div>
							<div className="ms-auto font-bold text-lg">
							{formatDate(ticketData.created)}
							</div>
						</div>
						
						<div className="text-center w-100 flex  text-sm mt-6">
							{ticketData.department}
						</div>
						<div className="w-full w-100 text-center  text-sm mt-1">
							{ticketData.job}
						</div>
					</div>

					{/* <div className="sm:mx-auto sm:w-full sm:max-w-sm">
						<img
							className="mx-auto h-25 w-auto"
							src={logo}
							alt="Jur Ur Logo"
						/>
					</div> */}

					<div className="relative flex items-center justify-center w-full mt-5 mb-4">
						<div className="w-full h-0.5 bg-transparent border-t border-dotted border-slate-300"></div>
						<div className="absolute -left-2.5 w-5 h-5 rounded-full bg-slate-100 border  clip-half-circle-left"></div>
						<div className="absolute -right-2.5 w-5 h-5 rounded-full bg-slate-100 border  clip-half-circle-right"></div>
					</div>

					<div className="p-6 text-center">
						<div className='mx-6 px-6' style={{ height: "auto", margin: "0 auto", width: "100%" }}>
							<QRCode
							size={500}
							style={{ height: "auto", maxWidth: "100%", width: "100%" }}
							value={ticketData.code}
							viewBox={`0 0 500 500`}
							level='H'
							/>
						</div>

						<button href="#" className="mt-3 inline-flex font-medium items-center text-blue-600 hover:underline" onClick={e => setStepNumber(1)}>
							Буцах
						</button>
					</div>
				</div>
			</div>
		)}


		{stepNumber === 3 && (
			<div className="mx-5 w-full rounded-xl  max-w-sm  overflow-hidden bg-white">
				<div id="ticket" className="flex min-h-full flex-1 flex-col justify-center p-6">
					<button href="#" className="text-sm inline-flex font-medium items-center text-blue-600 hover:underline" onClick={e => setStepNumber(1)}>
					<FontAwesomeIcon icon={faArrowLeft} className='me-2'/>Нүүр
					</button>
					<div className="mt-3 text-sm font-bold">Хоолны хуваарийн хүснэгт</div>
					<table className='table table-fixed mt-3 border-collapse border border-slate-400 text-sm'>
						<thead>
							<tr>
								<td className='border border-slate-300 px-2 py-1 font-bold'>Огноо</td>
								<td className='border border-slate-300 px-2 py-1 font-bold text-center'>Өглөө<br/>6-10</td>
								<td className='border border-slate-300 px-2 py-1 font-bold text-center'>Өдөр<br/>10-14</td>
								<td className='border border-slate-300 px-2 py-1 font-bold text-center'>Орой<br/>16-21</td>
								<td className='border border-slate-300 px-2 py-1 font-bold text-center'>Шөнө<br/>21-3</td>
							</tr>
						</thead>
						<tbody>
						{personalScheduleDates.map((date, index) => (
						<tr key={index}>
							<td className='border border-slate-300 px-2 py-1'>{date}</td>
							<td key={`${date}-morning`} className='border border-slate-300 px-2 py-1 text-center' onClick={e=>changePersonalSchedule(index, date, "morning", personalSchedules[index].morning, personalSchedules[index].pk)}>
							{
							personalSchedules[index].morning !== null ? (
								personalSchedules[index].morning ? (
									<FontAwesomeIcon icon={faCheckCircle} color="green" />
								) : (
									<FontAwesomeIcon icon={faTimesCircle} color="red" />
								)):(
									<FontAwesomeIcon icon={faQuestionCircle} color="grey" />
								)
							}
							</td>
							<td key={`${date}-afternoon`} className='border border-slate-300 px-2 py-1 text-center' onClick={e=>changePersonalSchedule(index, date, "afternoon", personalSchedules[index].afternoon, personalSchedules[index].pk)}>
							{
							personalSchedules[index].afternoon !== null ? (
								personalSchedules[index].afternoon ? (
									<FontAwesomeIcon icon={faCheckCircle} color="green" />
								) : (
									<FontAwesomeIcon icon={faTimesCircle} color="red" />
								)):(
									<FontAwesomeIcon icon={faQuestionCircle} color="grey" />
								)
							}
							</td>
							<td key={`${date}-evening`} className='border border-slate-300 px-2 py-1 text-center' onClick={e=>changePersonalSchedule(index, date, "evening", personalSchedules[index].evening, personalSchedules[index].pk)}>
							{
							personalSchedules[index].evening !== null ? (
								personalSchedules[index].evening ? (
									<FontAwesomeIcon icon={faCheckCircle} color="green" />
								) : (
									<FontAwesomeIcon icon={faTimesCircle} color="red" />
								)):(
									<FontAwesomeIcon icon={faQuestionCircle} color="grey" />
								)
							}
							</td>
							<td key={`${date}-night`} className='border border-slate-300 px-2 py-1 text-center' onClick={e=>changePersonalSchedule(index, date, "night", personalSchedules[index].night, personalSchedules[index].pk)}>
							{
							personalSchedules[index].night !== null ? (
								personalSchedules[index].night ? (
									<FontAwesomeIcon icon={faCheckCircle} color="green" />
								) : (
									<FontAwesomeIcon icon={faTimesCircle} color="red" />
								)):(
									<FontAwesomeIcon icon={faQuestionCircle} color="grey" />
								)
							}
							</td>
						</tr>
						))}
						</tbody>
					</table>
					
					{isAdmin && (
						<button
							disabled={isSubmitting}
							id="submit-button"
							type="submit"
							onClick={e => scheduleAdmin()}
							className="mt-4 flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
						{isSubmitting ? 
						<div role="status">
							<svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
								<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
							</svg>
							<span className="sr-only">Loading...</span>
						</div>
						: 'Админ удирдлага'}
						</button>
					)}
					
				</div>
			</div>
		)}

		{stepNumber === 4 && (
			<div className="mx-5 w-full rounded-xl  max-w-5xl bg-white">
				<div id="ticket" className="flex min-h-full flex-1 flex-col justify-center p-6">
					<button href="#" className=" inline-flex font-medium items-center text-sm text-blue-600 hover:underline" onClick={e => setStepNumber(1)}>
						<FontAwesomeIcon icon={faArrowLeft} className='me-2'/>Нүүр
					</button>
					<div className="mt-3 text-sm font-bold">Админ удирдлага</div>
					<div className="border-b border-gray-200 dark:border-gray-700">
						<ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
							<li className="me-2">
								<button
								className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
									currentMeal === 'morning' ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
								}`}
								onClick={e=>setCurrentmeal("morning")}
								>
									<FontAwesomeIcon icon={faSun} className='me-2'/>
									Өглөө
								</button>
							</li>
							<li className="me-2">
								<button
								className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
									currentMeal === 'afternoon' ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
								}`}
								onClick={e=>setCurrentmeal("afternoon")}
								>
									<FontAwesomeIcon icon={faBowlFood} className='me-2'/>
									Өдөр
								</button>
							</li>
							<li className="me-2">
								<button
								className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
									currentMeal === 'evening' ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
								}`}
								onClick={e=>setCurrentmeal("evening")}
								>
									<FontAwesomeIcon icon={faCloudMoon} className='me-2'/>
									Орой
								</button>
							</li>
							<li className="me-2">
								<button
								className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
									currentMeal === 'night' ? 'text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
								}`}
								onClick={e=>setCurrentmeal("night")}
								>
									<FontAwesomeIcon icon={faMoon} className='me-2'/>
									Шөнө
								</button>
							</li>
						</ul>
					</div>
								

					
					<div className='width-[100%] overflow-auto'>
						<table className='table table-fixed mt-3 border-collapse border border-slate-400 text-xs'>
							<thead>
							<tr>
								<td className='border border-slate-300 px-2 py-1 font-bold text-xs'>Нэр</td>
								<td className='border border-slate-300 px-2 py-1 font-bold text-xs'>Нэгж</td>
								{scheduleDates.map((date, index) => (
									<td key={index} className='border border-slate-300 px-2 py-1 font-bold text-xs text-center'><div>{date}</div></td>
								))}
							</tr>
							</thead>
							<SchedulesTable schedules={schedules} changeSchedule={changeSchedule} currentMeal={currentMeal} />
						</table>
					</div>
					

					<div className='text-sm italic mt-5'>Загварын sheet нэрийг 'Sheet1' гэдгээс өөрчилж болохгүй! Мөн хуваарь тавих буюу нүдээс бусад мөрийг өөрчилсөн 
					тохиолдолд мэдээлэл орохгүй байх магадлалтай. Хоол авах хүнийг мөрөөс хараад, багана дээрээс өдрийг хараад авах эрх үүсгэх нүдэнд 1 гэж тавина. Харин авахгүй өдөр хоосон орхих эсвэл 0 гэж тавина уу.
					Хэрвээ та аль нэг ажилтны хуваарьт өөрчлөлт оруулахыг хүсэхгүй бол мөрийг нь устгаж болно.
					</div>
					
					<div className='flex mt-3 justify-between'>
						<div className="flex-initial w-1/3">
							<button
								disabled={isSubmitting}
								id="submit-button"
								type="submit"
								onClick={exportToExcel}
								className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
							{isSubmitting ? 
							<div role="status">
								<svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
								</svg>
								<span className="sr-only">Loading...</span>
							</div>
							: 'Загвар татах'}
							</button>
						</div>
						<div className="flex w-2/3 justify-end">
							<FileUploader
								onFileSelect={handleFileSelect}
								keyProp={fileKey}
								fileName={fileName}
								clearFileName={clearFileName}
							/>
							<button
								disabled={isSubmitting}
								id="submit-button"
								type="submit"
								onClick={e => processFile()}
								className="ms-3 flex justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
							{isSubmitting ? 
							<div role="status">
								<svg aria-hidden="true" className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
								</svg>
								<span className="sr-only">Loading...</span>
							</div>
							: 'Excel оруулах'}
							</button>
						</div>
					</div>
				</div>
			</div>
		)}
       </>
    );
}

export default FoodElement;